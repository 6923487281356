import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {ActiveSession} from '../../state/user-management.types'
import {hl} from '../commons/Highlight.utils'

interface Props {
  activeSession: ActiveSession
  filter: string | null | undefined
}
export function ActiveSessionListItem({activeSession, filter}: Props) {
  const username = hl(activeSession.username, filter)
  return (
    <Flex fontSize="small" pt={1} direction="column" nowrap pb={1} fullWidth overflowHidden>
      <Box ellipsis>{activeSession.id}</Box>
      <Box>{username}</Box>
      <Box ellipsis>{activeSession.issuedAt}</Box>
    </Flex>
  )
}
