import queryString from 'query-string'
import {Observable} from 'rxjs'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {map} from 'rxjs/operators'
import {getHeaders} from '../../app/service-utils'
import {
  ActiveSession,
  EBookSearchResult,
  KeycloakUsers,
  KvSearchResult,
  Lohndaten,
  Permission,
  UserGroup,
  UserGroupUpdate,
  UserManagementResource,
  UserManagementUser,
  UserManagementUserGroups,
} from './user-management.types'

export const loadKeycloakUsers = (
  ajax: AjaxCreationMethod,
  filter: string | undefined | null = ''
): Observable<KeycloakUsers> => {
  const queryParams = queryString.stringify({
    filter,
    start: 0,
    count: 100,
  })
  return ajax.getJSON(`/api/v1/user-management/keycloak-users/?${queryParams}`, getHeaders())
}

export const loadUsers$ = (
  ajax: AjaxCreationMethod,
  userId: string | undefined | null = ''
): Observable<UserManagementUser> => {
  return ajax.getJSON(`/api/v1/user-management/users/${userId}`, getHeaders())
}

export const loadUserGroups$ = (
  ajax: AjaxCreationMethod,
  filter: string | undefined | null = ''
): Observable<UserManagementUserGroups> => {
  const queryParams = queryString.stringify({
    filter,
    start: 0,
    count: 100,
  })
  return ajax.getJSON(`/api/v1/user-management/user-groups/?${queryParams}`, getHeaders())
}

export const loadUserGroup$ = (
  ajax: AjaxCreationMethod,
  userGroupId: string | undefined | null = ''
): Observable<UserGroup> => {
  return ajax.getJSON(`/api/v1/user-management/user-groups/${userGroupId}`, getHeaders())
}

export const saveUserGroup$ = (ajax: AjaxCreationMethod, userGroupUpdate: UserGroupUpdate): Observable<void> => {
  return ajax
    .put(`/api/v1/user-management/user-groups/${userGroupUpdate.id}`, userGroupUpdate, {
      ...getHeaders(),
      'Content-Type': 'application/json',
    })
    .pipe(map(() => undefined))
}

export const addAuthenticationToken$ = (ajax: AjaxCreationMethod, userId: string): Observable<void> => {
  return ajax
    .post(
      `/api/v1/user-management/users/${userId}/authentication-token`,
      {},
      {
        ...getHeaders(),
        'Content-Type': 'application/json',
      }
    )
    .pipe(map(() => undefined))
}

export const deleteAuthenticationToken$ = (ajax: AjaxCreationMethod, userId: string): Observable<void> => {
  return ajax
    .delete(`/api/v1/user-management/users/${userId}/authentication-token`, {
      ...getHeaders(),
      'Content-Type': 'application/json',
    })
    .pipe(map(() => undefined))
}

export const addUserPermission$ = (
  ajax: AjaxCreationMethod,
  userId: string,
  resource: UserManagementResource
): Observable<void> => {
  return ajax
    .put(
      `/api/v1/user-management/users/${userId}/permissions/${resource.id}`,
      {
        resourceId: resource.id,
        resourceName: resource.name,
      },
      {
        ...getHeaders(),
        'Content-Type': 'application/json',
      }
    )
    .pipe(map(() => undefined))
}

export const removeUserPermission$ = (
  ajax: AjaxCreationMethod,
  userId: string,
  permission: Permission
): Observable<void> => {
  return ajax
    .delete(`/api/v1/user-management/users/${userId}/permissions/${permission.resourceId}`, {
      ...getHeaders(),
    })
    .pipe(map(() => undefined))
}

export const addUserToUserGroup$ = (
  ajax: AjaxCreationMethod,
  userId: string,
  userGroup: UserGroup,
  role: string
): Observable<void> => {
  return ajax
    .put(
      `/api/v1/user-management/users/${userId}/user-groups/${userGroup.id}`,
      {
        groupId: userGroup.id,
        role,
      },
      {
        ...getHeaders(),
        'Content-Type': 'application/json',
      }
    )
    .pipe(map(() => undefined))
}

export const removeUserFromUserGroup$ = (
  ajax: AjaxCreationMethod,
  userId: string,
  userGroup: UserGroup
): Observable<void> => {
  return ajax
    .delete(`/api/v1/user-management/users/${userId}/user-groups/${userGroup.id}`, {
      ...getHeaders(),
    })
    .pipe(map(() => undefined))
}

export const addUserGroupPermission$ = (
  ajax: AjaxCreationMethod,
  userGroupId: string,
  resource: UserManagementResource
): Observable<void> => {
  return ajax
    .put(
      `/api/v1/user-management/user-groups/${userGroupId}/permissions/${resource.id}`,
      {
        resourceId: resource.id,
        resourceName: resource.name,
      },
      {
        ...getHeaders(),
        'Content-Type': 'application/json',
      }
    )
    .pipe(map(() => undefined))
}

export const removeUserGroupPermission$ = (
  ajax: AjaxCreationMethod,
  userGroupId: string,
  permission: Permission
): Observable<void> => {
  return ajax
    .delete(`/api/v1/user-management/user-groups/${userGroupId}/permissions/${permission.resourceId}`, {
      ...getHeaders(),
    })
    .pipe(map(() => undefined))
}

export const loadActiveSessions$ = (ajax: AjaxCreationMethod): Observable<ActiveSession[]> => {
  return ajax.getJSON(`/api/v1/auth/active-sessions`, getHeaders())
}

export const loadKvs$ = (
  ajax: AjaxCreationMethod,
  userInput: string | undefined | null = ''
): Observable<KvSearchResult> => {
  return ajax.getJSON(
    `/api/v1/kv/auto-suggest?&page-size=100&query=${encodeURIComponent(userInput || '')}`,
    getHeaders()
  )
}
export const loadEbooks$ = (
  ajax: AjaxCreationMethod,
  userInput: string | undefined | null = ''
): Observable<EBookSearchResult> => {
  return ajax.getJSON(`/api/v1/webc/ebooks?&page-size=100&query=${encodeURIComponent(userInput || '')}`, getHeaders())
}

export const loadLohndaten$ = (
  ajax: AjaxCreationMethod,
  userInput: string | undefined | null = ''
): Observable<Lohndaten> => {
  return ajax.getJSON<Lohndaten>('/lohndaten/kollektivvertraege?heute=true&lohndatenstatus=ALL').pipe(
    map((lohndaten) => {
      const nextLohndateneinträge = (lohndaten.lohndateneinträge || []).filter((eintrag) => {
        if (
          userInput &&
          userInput.length > 1 &&
          eintrag.link.name.toUpperCase().indexOf(userInput.toUpperCase()) >= 0
        ) {
          return true
        } else if (!userInput || userInput.length <= 1) {
          return true
        }
        return false
      })
      return {
        ...lohndaten,
        count: nextLohndateneinträge.length,
        lohndateneinträge: nextLohndateneinträge,
      }
    })
  )
}
