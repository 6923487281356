import {Box} from '@indoqa/style-system'
import React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'

interface Props {
  value?: string
}

export function ActiveSessionDetailsField({value}: Props) {
  const {theme, css} = useFela<Theme>()
  return (
    <Box fullWidth maxWidth={500} height={44} mr={2}>
      <input
        value={value}
        readOnly
        className={css({
          color: theme.colors.textReduced,
          border: theme.borders.border1,
          flexGrow: 1,
          width: '100%',
          padding: theme.spacing.space2,
        })}
      />
    </Box>
  )
}
