import {Flex, Text} from '@indoqa/style-system'
import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useCallback} from 'react'
import {ajax} from 'rxjs/ajax'
import {ActiveSessionDetailsPanel} from '../components/active-sessions/ActiveSessionDetailsPanel'
import {ActiveSessionsListPanel} from '../components/active-sessions/ActiveSessionsListPanel'
import {UserManagementLayout} from '../layout/UserManagementLayout'
import {loadActiveSessions$} from '../state/user-management.services'
import {ActiveSession} from '../state/user-management.types'
import {BV_REQUEST_PARAMS} from '../user-management.paths'
export const UserManagementSessionsPage = () => {
  const sessionId = useQueryParam(BV_REQUEST_PARAMS.sessionId)

  const [session, setSession] = React.useState<ActiveSession | null>(null)
  const [loading, setLoading] = React.useState(true)

  const loadSessions = useCallback(() => {
    loadActiveSessions$(ajax).subscribe((sessions) => {
      const session = sessions.find((session) => session.id === sessionId)
      if (session) {
        setSession(session)
      }
      setLoading(false)
    })
  }, [sessionId])

  React.useEffect(() => {
    loadSessions()
  }, [loadSessions])

  // const contentPanel = <Box>{session?.username}</Box>
  const contentPanel = session ? (
    <ActiveSessionDetailsPanel session={session} />
  ) : sessionId && !loading ? (
    <Flex fullHeight fullWidth center>
      Die Session mit der ID&nbsp;<Text color="red">{sessionId}</Text>&nbsp;existiert nicht.
    </Flex>
  ) : null
  const listPanel = <ActiveSessionsListPanel sessionId={sessionId} />

  return <UserManagementLayout contentPanel={contentPanel} listPanel={listPanel} />
}
